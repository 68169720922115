import React, { useEffect } from 'react';
import styled from 'styled-components';
import { rtypeN16r, rtypeT21sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { rgba } from 'polished';

const Title = styled.div`
    ${rtypeT21sb};
    background: ${colors.purple};
    color: ${colors.white};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 30px;
    text-align: center;
`;

const Body = styled.div`
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0;
    min-height: 540px;
    max-height: 540px;
    overflow-y: scroll;
    display: flex;
    ${rtypeN16r};
`;

const Container = styled.div`
    box-shadow: 0 2px 8px ${rgba(colors.darkBlue, 0.25)};
`;

const DonationForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://korusimpact.infoodle.com/js/embed_form.js";
        script.setAttribute("data-form", "f1372eec-39ab-40f8-9c7f-1bdefd2ab8a5");
        script.async = true;

        document.getElementById('embed-container').appendChild(script);
    }, []);

    return (
        <Container>
            <Title>Donation Form</Title>
            <Body>
                <div style={{ display: 'flex', flex: 1 }} id="embed-container">
                    <div style={{ display: 'flex', flex: 1 }} id="embed-form-f1372eec-39ab-40f8-9c7f-1bdefd2ab8a5"></div>
                </div>
            </Body>
        </Container>
    );
}

export default DonationForm;
